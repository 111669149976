/** 
const { default: weaviate } = require('weaviate-ts-client');
console.log(process.env.REACT_APP_WEAVIATE_HOST, process.env.REACT_APP_WEAVIATE_API_KEY, process.env.REACT_APP_OPENAI_API_KEY)
const client = weaviate.client({
  scheme: 'https',
  host: "sandbox-engageai-g6igcngk.weaviate.network",  // Replace with your endpoint
  headers: {'X-OpenAI-Api-Key': process.env.REACT_APP_OPENAI_API_KEY},
 
});*/


export default async function DatabaseQueryService(query) {
    try{
        /** 
        const response = await client.graphql
            .get()
            .withClassName('Podcast')
            .withFields('link title')
            .withNearText({
                concepts: [query]
            })
            .withGenerate({
                groupedTask: query + " .insert '<br>' into the text before each paragraph.",
            })
            .withLimit(3)
            .do()
        const answer = response["data"]["Get"]["Podcast"][0]["_additional"]["generate"]["groupedResult"]
        const episodes = []
        for (let e of response["data"]["Get"]["Podcast"]){
            const tempObj = {
                title: e.title,
                link: e.link
            }
            episodes.push(tempObj)
        }
        console.log(response["data"]["Get"]["Podcast"])
        return {answer: answer, episodes:episodes} */
        const options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({ query:query }),
          };
          return fetch("https://prod.engageai-server.com/api/weaviate-search", options);
        }
    catch (e) {console.log(e)}
}
