import './App.css';
import React, { useState} from "react";
import DatabaseQueryService from './services/DatabaseQueryService';
import ddaLogo from './static_files/dda_logo.svg'
import sun from './static_files/sun.svg'
import alert from './static_files/alert-triangle.svg'
import { Bars } from  'react-loader-spinner'

function App() {
  const [answer, setAnswer] = useState("");
  const [query, setQuery] = useState("");
  const [links, setLinks] = useState();
  const [loading, setLoading] = useState(false);


  const onClickSampleQuery = (e) => {
    console.log(e.target.innerText);
    setLinks();
    setLoading(true);
    setQuery(e.target.innerText);
    DatabaseQueryService(e.target.innerText).then((res)=>{
      console.log(res);
      if (res.status === 200){
        res.json().then((json)=>{
          setLoading(false);
          setAnswer(json.answer);
          console.log(json.episodes)
          setLinks(json.episodes);
        })
      }
      else {
        setLoading(false);
        setAnswer("Server error");
      }
    }).catch(err => {
      setLoading(false);
      console.error(err);
      setAnswer("Server error")
    });
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLinks();
    if (query){
      setLoading(true);
      console.log(query);
      DatabaseQueryService(query).then((res)=>{
        console.log(res);
        if (res.status === 200){
          res.json().then((json)=>{
            setLoading(false);
            setAnswer(json.answer);
            console.log(json.episodes)
            setLinks(json.episodes);
          })
        }
        else {
          setLoading(false);
          setAnswer("Server error")
        }
      }).catch(err => {
        setLoading(false);
        console.error(err);
        setAnswer("Server error")
      });
    }
  }
  
  return (
    <div className="App">
      <div className="header">
        <div ><a href='https://ddalabs.ai/' rel='noreferrer' target='_blank'><img src={ddaLogo} alt='DDA'></img></a></div>
      </div>
      {/**<div>
        <textarea id="query" placeholder="Search for a podcast" onChange={handleChange}
          style={{width:"100%"}}
        ></textarea>
        <button onClick={onSearch}>Search</button>
  </div>*/}
      <h4 style={{textAlign:"center", fontSize:"26px"}}>Ask <a href='https://ddalabs.ai/the-analytics-show/' rel='noreferrer' target="_blank">The <span style={{color:"#2097F3"}}>Analytics</span> Show</a></h4>
      <p style={{textAlign:"center"}}>Uncover insights. Explore episodes of The Analytics Show by your favourite topics.</p>
      <form onSubmit={handleSubmit} data-testid='search'>
        <div className="textarea">
          <div id="query" className="textarea-text">
              <input required placeholder='Find me an episode about AI in healthcare' type='text' onChange={handleChange}></input>
          </div>
        </div>
      </form>
      <div className='flex' style={{textAlign:"center"}}>
        <div style={{display:"flex", flexDirection:"column"}}>
          <div><img src={sun} alt=''></img></div>
          <div>Examples</div>
          <div className="sample-query" onClick={onClickSampleQuery}>What episode features founders of their own companies?</div>
          <div className="sample-query" onClick={onClickSampleQuery}>What episode delves into AI?</div>
          <div className="sample-query" onClick={onClickSampleQuery}>Who shares their expertise on machine learning?</div>
          <div className="sample-query" onClick={onClickSampleQuery}>What is Episode 1 about?</div>
        </div>
        <div style={{display:"flex", flexDirection:"column"}}>
        <div><img src={alert} alt=''></img></div>
          <div>Limitations</div>
          <div className="limitation">OpenAI server may be down from time to time.</div>
          <div className="limitation">May generate incorrect information. It's an LLM after all.</div>
          <div className="limitation">May not be up to date. Source text is taken until Episode 132.</div>
          <div className="limitation">Use numbers instead of string to search for episode, e.g. 'Episode 1' instead of 'First episode'.</div>
        </div>
      </div>
      <div id="answer" className="answer-block">{
        loading ? <div style={{textAlign:"center"}}>
          <Bars
            height="40"
            width="40"
            color="#2097F3"
            ariaLabel="bars-loading"
            wrapperStyle={{display:"block"}}
            wrapperClass=""
            visible={true}
          />
        </div> : answer}
        <br></br>
        <div style={{marginTop:"1rem"}}>
          {links ? links.map((link)=>{
            return <div><a href={link.link} className="link" target='_blank' rel='noreferrer'>{link.title}</a></div>
          }):''}
        </div>
        </div>
    </div>
  );
}

export default App;
